import React, { FC } from 'react';
import { Card } from 'reactstrap';
import { ProductModel } from '../../shared/orders';
import { displayName, truncateText } from '../utils';
import { BuyButton, ProductPrice } from '../utils/product';
import { ProductBadges } from '.';

interface ProductCardProps {
  readonly hidePrice?: boolean;
  readonly product: ProductModel;
  onSelect(id: number): void;
}

export const ProductCard: FC<ProductCardProps> = ({ hidePrice, product, onSelect }) => {
  const image = product.images.find(({ type }) => type === 'primary');
  return (
    <Card className="productItem" id={`product${product.id}`}>
      {image && (
        <img
          src={`/api/products/${product.id}/images/${image.id}`}
          style={{
            float: 'left',
            marginBottom: '12px',
            marginRight: '12px',
            maxHeight: '96px',
            maxWidth: '96px',
          }}
        />
      )}
      <div className="productInfo">
        <h5>
          {displayName(product)}
          <ProductBadges float product={product} />
        </h5>
        <p>{truncateText(product.description, 256) || <i>No Description Provided</i>}</p>
      </div>
      <div className="productPurchaseBar">
        {!hidePrice && <ProductPrice product={product} />}
        <BuyButton label="View Product" onSelect={onSelect} product={product} />
      </div>
    </Card>
  );
};
