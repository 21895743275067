import React, { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import {
  DealerApplication,
  DealerArea,
  DealerFullModel,
  UpdateDealerApplicationPayload,
} from '../../../../shared/dealer';
import { OptionDataPayload, OptionDataValue } from '../../../../shared/options';
import { ProductModel } from '../../../../shared/orders';
import { MessageCard, ProductOptionViewer } from '../../../components';
import {
  displayName,
  Fetcher,
  handleUnknownOptionError,
  isLogicError,
  toggleElementInArray,
  useConfig,
  useFetcher,
  useForm,
  useInputModel,
  useObject,
  useUser,
} from '../../../utils';
import { captureError, LogicError } from '../../../utils/errorHandling';
import { getProductPrice } from '../../../utils/product';

interface DealerApplicationFormProps {
  readonly dealer: DealerFullModel;
  readonly dealerAreaId: number;
  readonly callback: () => void;
}

export function cleanupApplicationOptions(
  payload: OptionDataPayload,
  product: ProductModel,
): OptionDataPayload {
  const result: OptionDataPayload = {};

  for (const option of product.options) {
    if (payload[option.id]) {
      result[option.id] = payload[option.id];
    }
  }

  for (const option of product.addons.flatMap((t) => t.options)) {
    if (payload[option.id]) {
      result[option.id] = payload[option.id];
    }
  }

  return result;
}

export const SpaceApplicationForm: FC<DealerApplicationFormProps> = ({
  dealer,
  dealerAreaId,
  callback,
}) => {
  const config = useConfig();
  const area = config.dealersAreas.find((t) => t.id === dealerAreaId)!;
  const inputApplication = dealer.applications.find((t) => t.areaId === dealerAreaId);
  const tableTypesRequest = useFetcher(async () => {
    return await api.getProductsByCategory(area.categoryId, inputApplication?.tableType.id);
  }, [inputApplication?.tableType.id]);

  if (!tableTypesRequest.data) {
    return <Fetcher result={tableTypesRequest} />;
  }

  return (
    <SpaceApplicationFormInner
      area={area}
      callback={callback}
      dealer={dealer}
      dealerAreaId={dealerAreaId}
      tableTypes={tableTypesRequest.data}
    />
  );
};

interface DealerApplicationFormPropsInner {
  readonly area: DealerArea;
  readonly dealer: DealerFullModel;
  readonly dealerAreaId: number;
  readonly tableTypes: ProductModel[];
  readonly callback: () => void;
}

const SpaceApplicationFormInner: FC<DealerApplicationFormPropsInner> = ({
  area,
  dealer,
  dealerAreaId,
  tableTypes,
  callback,
}) => {
  const inputApplication = dealer.applications.find((t) => t.areaId === dealerAreaId);
  const disabled = !!inputApplication && inputApplication.status !== 'pending';
  const [application, setApplication] = useState<UpdateDealerApplicationPayload>(() => ({
    requests: inputApplication?.dealerRequestTypes.map((t) => t.id) ?? [],
    specialRequests: inputApplication?.specialRequests ?? '',
    tableTypeId: inputApplication?.tableType.id ?? tableTypes[0]?.id,
    status: inputApplication?.status ?? 'pending',
    tableNumber: inputApplication?.tableNumber ?? '',
    options: inputApplication?.options ?? {},
  }));

  const form = useForm(async () => {
    const table = tableTypes.find((t) => t.id === application.tableTypeId)!;
    try {
      await api.updateDealerApplication(dealer.id, dealerAreaId, {
        ...application,
        options: cleanupApplicationOptions(application.options, table),
      });
    } catch (error) {
      if (isLogicError(error, LogicError.UnknownProductOption)) {
        handleUnknownOptionError(error, table.options);
      } else {
        captureError(error as Error);
      }
    }

    callback();
    toast.success('Your application has been updated.');
  }, [dealer.id, dealerAreaId, tableTypes, application]);

  if (!area.open) {
    return (
      <Col xs={12}>
        <Alert color="danger">{area.name} is closed.</Alert>
      </Col>
    );
  }

  return (
    <form onSubmit={form.onSubmit}>
      <Row className="justify-content-center" id="areaApplicationForm">
        <Col lg={8} sm={12}>
          {area.full && (
            <Alert color="warning">
              The {area.name} is full. Applications can still be made in case a seat becomes
              available.
            </Alert>
          )}
          {disabled && (
            <Alert color="warning">
              The application is {application.status} and can not be modified.
            </Alert>
          )}
        </Col>
        <Col className="margin-bottom-10" lg={8} sm={12}>
          <ApplicationBody
            application={application}
            disabled={disabled}
            isNew={!inputApplication}
            paidOrderItemId={inputApplication?.paidOrderItemId ?? undefined}
            setApplication={setApplication}
            tableTypes={tableTypes}
          />
        </Col>
        {tableTypes.length > 0 && (
          <Col className="margin-top-10" lg={6} sm={12}>
            <Button
              block
              color="primary"
              disabled={disabled || form.saving}
              id="submitAreaApplication"
              type="submit"
            >
              {inputApplication ? 'Update' : 'Submit'}
            </Button>
          </Col>
        )}
        <Col sm={12} />
        <Col className="margin-top-10" lg={6} sm={12}>
          <DeleteApplicationButton
            application={inputApplication}
            dealerAreaId={dealerAreaId}
            dealerId={dealer.id}
          />
        </Col>
      </Row>
    </form>
  );
};

interface ApplicationBodyProps {
  readonly application: UpdateDealerApplicationPayload;
  readonly setApplication: Dispatch<SetStateAction<UpdateDealerApplicationPayload>>;
  readonly disabled: boolean;
  readonly tableTypes: ProductModel[];
  readonly paidOrderItemId: number | undefined;
  readonly isNew: boolean;
}

const ApplicationBody: FC<ApplicationBodyProps> = ({
  application,
  disabled,
  tableTypes,
  paidOrderItemId,
  setApplication,
  isNew,
}) => {
  if (tableTypes.length === 0) {
    return (
      <Col lg={12} xs={12}>
        <MessageCard icon="block" id="noTablesFailed" level="danger">
          <CardTitle>No table types available</CardTitle>
          No table types available to create an application.
        </MessageCard>
      </Col>
    );
  }

  if (isNew) {
    return (
      <ApplicationCard
        application={application}
        disabled={disabled}
        setApplication={setApplication}
        tableTypes={tableTypes}
      />
    );
  }

  return (
    <Row className="justify-content-center">
      <Col lg={6} sm={12}>
        <ApplicationCard
          application={application}
          disabled={disabled}
          setApplication={setApplication}
          tableTypes={tableTypes}
        />
      </Col>
      <Col lg={6} sm={12}>
        <Card id="nextStepsCard">
          <CardBody>
            <CardTitle>Next Steps</CardTitle>
            <NextStepsCard application={application} paidOrderItemId={paidOrderItemId} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

interface NextStepsCardProps {
  readonly application: UpdateDealerApplicationPayload;
  readonly paidOrderItemId: number | undefined;
}

const NextStepsCard: FC<NextStepsCardProps> = ({ application, paidOrderItemId }) => {
  const config = useConfig();

  if (application.status === 'pending') {
    return (
      <ul>
        <li>
          Your application has been submitted and is pending approval by the event. No action is
          required at this time.
        </li>
      </ul>
    );
  }

  if (application.status === 'rejected') {
    return (
      <ul>
        <li>
          Thank you for taking the time to submit a vendor application, but unfortunately your
          application has been rejected by the event at this time.
        </li>
      </ul>
    );
  }

  if (application.status === 'waitlisted') {
    return (
      <ul>
        <li>
          Your application is waitlisted and is pending approval by the event. No action is required
          at this time.
        </li>
        <li>You will be contacted if your application status changes.</li>
      </ul>
    );
  }

  if (!paidOrderItemId) {
    // TODO: Add payment amount and link.
    return (
      <ul>
        <li>Your application was approved and is now pending payment.</li>
        <li>
          If you have any assistants that will be helping you at the event, please add them via the{' '}
          <Link to="/vendor/assistants">Vendor Assistants</Link> page.
        </li>
        <li>
          If you need to edit or cancel your application, please contact{' '}
          {config.contact.email.dealers}.
        </li>
      </ul>
    );
  }

  return (
    <ul>
      <li>
        Your application was approved and has been paid. You're ready for the event and we look
        forward to welcoming you!
      </li>
      <li>
        If you have any assistants that will be helping you at the event, please add them via the{' '}
        <Link to="/vendor/assistants">Vendor Assistants</Link> page.
      </li>
      <li>
        If you need to edit or cancel your application, please contact{' '}
        {config.contact.email.dealers}.
      </li>
    </ul>
  );
};

interface ApplicationCardProps {
  readonly application: UpdateDealerApplicationPayload;
  readonly setApplication: Dispatch<SetStateAction<UpdateDealerApplicationPayload>>;
  readonly disabled: boolean;
  readonly tableTypes: ProductModel[];
}

const ApplicationCard: FC<ApplicationCardProps> = ({
  application,
  setApplication,
  disabled,
  tableTypes,
}) => {
  const setSpecialRequests = useInputModel(setApplication, 'specialRequests');
  const [tableTypeId, setTableTypeId] = useObject(application, setApplication, 'tableTypeId');
  const selectedTableType = tableTypes.find((t) => application.tableTypeId === t.id);

  return (
    <Card>
      <CardBody>
        <CardTitle>Space Information</CardTitle>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label for="specialRequests">Special Requests</Label>
              <Input
                disabled={disabled}
                id="specialRequests"
                onChange={setSpecialRequests}
                type="textarea"
                value={application.specialRequests}
              />
            </FormGroup>
            <TableTypeSelect
              disabled={disabled}
              selectedTableTypeId={tableTypeId}
              setSelectedTableTypeId={setTableTypeId}
              tableTypes={tableTypes}
            />
          </Col>
          {selectedTableType && (
            <>
              <Col xs={12}>
                <ApplicationAddons
                  application={application}
                  disabled={disabled}
                  selectedTableType={selectedTableType}
                  setApplication={setApplication}
                />
              </Col>
              <Col xs={12}>
                <ApplicationOptionListRender
                  application={application}
                  disabled={disabled}
                  product={selectedTableType}
                  setApplication={setApplication}
                />
              </Col>
            </>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

interface TableTypeSelectProps {
  readonly tableTypes: ProductModel[];
  readonly selectedTableTypeId: number;
  readonly setSelectedTableTypeId: (value: number) => void;
  readonly disabled: boolean;
}

const TableTypeSelect: FC<TableTypeSelectProps> = (props) => {
  const { tableTypes, selectedTableTypeId, setSelectedTableTypeId, disabled } = props;

  return (
    <FormGroup>
      <Label for="tableTypeId">Table Type</Label>
      <Input
        disabled={disabled}
        id="tableTypeId"
        onChange={(e) => {
          setSelectedTableTypeId(Number.parseInt(e.target.value, 10));
        }}
        type="select"
        value={selectedTableTypeId.toString()}
      >
        {tableTypes.map((tableType) => {
          return (
            <option key={tableType.id} value={tableType.id}>
              {displayName(tableType)} ({getProductPrice(tableType).price})
            </option>
          );
        })}
      </Input>
      <small>{tableTypes.find((t) => selectedTableTypeId === t.id)?.description ?? ''}</small>
    </FormGroup>
  );
};

interface ApplicationAddonsProps {
  readonly selectedTableType?: ProductModel;
  readonly application: UpdateDealerApplicationPayload;
  readonly setApplication: Dispatch<SetStateAction<UpdateDealerApplicationPayload>>;
  readonly disabled: boolean;
}

const ApplicationAddons: FC<ApplicationAddonsProps> = ({
  application,
  selectedTableType,
  setApplication,
  disabled,
}) => {
  if (!selectedTableType) {
    return null;
  }

  return (
    <>
      {selectedTableType.addons.map((addon) => {
        const key = `requests${addon.id}`;
        return (
          <div className="custom-control custom-checkbox margin-top-10" key={key}>
            <Input
              checked={application.requests.includes(addon.id)}
              className="custom-control-input"
              disabled={disabled}
              id={key}
              onChange={() => {
                setApplication((old) => ({
                  ...old,
                  requests: toggleElementInArray(old.requests, addon.id),
                }));
              }}
              type="checkbox"
              value={addon.id}
            />
            <Label className="custom-control-label" for={key}>
              {displayName(addon)} {!addon.isFree && <>({getProductPrice(addon).price})</>}
            </Label>
          </div>
        );
      })}
    </>
  );
};

const DeleteApplicationButton: FC<{
  readonly dealerId: number;
  readonly dealerAreaId: number;
  readonly application?: DealerApplication;
}> = ({ dealerAreaId, application, dealerId }) => {
  const history = useHistory();
  const user = useUser()!;

  if (!application || application.status !== 'pending') {
    return null;
  }

  async function deleteApplication(): Promise<void> {
    try {
      await api.deleteActiveDealerApplication(dealerId, dealerAreaId);
      let dealer = null;
      try {
        dealer = await api.getUserDealer(user.id);
      } catch {
        /* NO-OP */
      }

      toast.success('Your vendor application has been deleted.');
      history.push(dealer ? '/vendor' : '/');
    } catch (error) {
      captureError(error as Error);
    }
  }

  return (
    <Button block color="danger" id="deleteApplication" onClick={deleteApplication} type="button">
      Delete Application
    </Button>
  );
};

interface ApplicationOptionListRenderProps {
  readonly application: UpdateDealerApplicationPayload;
  readonly setApplication: Dispatch<SetStateAction<UpdateDealerApplicationPayload>>;
  readonly product: ProductModel;
  readonly disabled: boolean;
}

export const ApplicationOptionListRender: FC<ApplicationOptionListRenderProps> = ({
  product,
  disabled,
  application,
  setApplication,
}) => {
  const onOptionChange = useCallback((id: number, value: OptionDataValue) => {
    setApplication((old) => ({
      ...old,
      options: {
        ...old.options,
        [id.toString()]: value,
      },
    }));
  }, []);

  const options = [...product.options];

  for (const addon of product.addons) {
    if (addon.isFree || application.requests.includes(addon.id)) {
      options.push(...addon.options);
    }
  }

  if (options.length === 0) {
    return null;
  }

  return (
    <div>
      <hr />
      {options.map((option) => {
        return (
          <ProductOptionViewer
            disabled={disabled}
            key={option.id}
            onChange={onOptionChange}
            option={option}
            value={application.options[option.id]}
          />
        );
      })}
    </div>
  );
};
