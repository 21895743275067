import React, { FC } from 'react';
import { Button, Card, Col } from 'reactstrap';
import { ProductModel } from '../../shared/orders';
import { RaffleWithUserDetails } from '../../shared/orders/raffle';
import { displayName } from '../utils';
import { BuyButton, ProductPrice } from '../utils/product';
import { LazyMarkdown } from '.';

interface RegTypeSelectProps {
  readonly product: ProductModel;
  readonly isSelected?: boolean;
  readonly isPaid?: boolean;
  readonly raffle?: RaffleWithUserDetails;
  onSelect(productId: number): void;
}

export const RegTypeSelect: FC<RegTypeSelectProps> = ({
  isSelected,
  isPaid,
  onSelect,
  product,
  raffle,
}) => {
  return (
    <Col
      className="margin-bottom-10"
      id={`product${product.id}`}
      key={`product${product.id}Cat${product.category.id}`}
      lg={6}
      xs={12}
    >
      <Card className="productItem markdown-container">
        <div className="productInfo">
          <h5>{displayName(product)}</h5>
          <LazyMarkdown source={product.description ?? '*No Description Provided*'} />
        </div>
        <div className="productPurchaseBar">
          {!isSelected && !isPaid && <ProductPrice product={product} />}
          {isSelected || isPaid ? (
            <Button
              className={isSelected ? 'continue' : 'cancelUpgrade'}
              color={isSelected ? 'white' : 'warning'}
              id={`selectProduct${product.id}`}
              onClick={() => {
                onSelect(product.id);
              }}
              outline
            >
              {isSelected ? 'Continue' : 'Cancel Upgrade'}
            </Button>
          ) : (
            <BuyButton label="Select" onSelect={onSelect} product={product} raffle={raffle} />
          )}
        </div>
      </Card>
    </Col>
  );
};
