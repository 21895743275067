import React, { FC } from 'react';
import { Button } from 'reactstrap';
import { ProductModel } from '../../shared/orders/product';
import { RaffleWithUserDetails } from '../../shared/orders/raffle';
import { Currency } from '../components/Currency';
import { isRaffleClosed } from '../models';
import { classNames } from '.';

interface ProductPriceProps {
  price: React.ReactNode;
  was?: React.ReactNode;
}

interface ProductRawPrice {
  price: number;
  was?: number;
}

export function getProductPrice(
  product: Pick<ProductModel, 'discounts' | 'price'>,
  quantity = 1,
): ProductPriceProps {
  const { price, was } = getProductRawPrice(product, quantity);

  return {
    price: <Currency value={price} />,
    was: was === undefined ? undefined : <Currency value={was} />,
  };
}

function getProductRawPrice(
  { discounts, price }: Pick<ProductModel, 'discounts' | 'price'>,
  quantity = 1,
): ProductRawPrice {
  if (!discounts || discounts.length === 0) {
    return { price: price * quantity };
  }

  return {
    price: Math.min(...discounts.map(({ price: discountPrice }) => discountPrice)) * quantity,
    was: price * quantity,
  };
}

export const ProductPrice: FC<{
  readonly product: ProductModel;
  readonly showDiscount?: boolean;
}> = ({ product, showDiscount }) => {
  const { was, price } = getProductPrice(product);
  return (
    <div className={classNames({ productWithDiscount: !!was }, 'productPurchaseBarPrice')}>
      {price}
      {showDiscount && was && (
        <>
          <br />
          <small>Was {was}</small>
        </>
      )}
    </div>
  );
};

interface BuyButtonProps {
  readonly product: ProductModel;
  readonly label?: string;
  readonly raffle?: RaffleWithUserDetails;
  onSelect(id: number): void;
}

export const BuyButton: FC<BuyButtonProps> = ({
  product,
  onSelect,
  raffle: userRaffle,
  label = 'Add to Cart',
}) => {
  const { id, stockStatus, raffle } = product;

  if (isRaffleClosed(userRaffle, raffle)) {
    return (
      <Button color="white" disabled id={`closedRaffle${id}`} outline>
        Closed Raffle
      </Button>
    );
  }

  if (stockStatus === 'soldOut' && raffle?.overflowEntries !== true) {
    return (
      <Button color="white" disabled outline>
        Sold Out
      </Button>
    );
  }

  return (
    <Button
      className="buy"
      color="success"
      id={`selectProduct${id}`}
      onClick={() => {
        onSelect(id);
      }}
    >
      {label}
    </Button>
  );
};
